import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'utils/with-i18next';
import styled from '@emotion/styled';

const CustomLink = ({ href, name, id, className }) => (
  <Link href={href} passHref>
    <a id={id} className={`${className ? className : ''}`} href={href}>
      {name}
    </a>
  </Link>
);

CustomLink.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
};

export default CustomLink;
